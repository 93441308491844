export const getTrimmedTitle = (string, maxLength) => {
  let excerpt = string || '';
  let MAX_LENGTH = maxLength || 100;
  if (excerpt.length > MAX_LENGTH) {
    let spaceFlag = excerpt[MAX_LENGTH] === ' ';
    while (!spaceFlag) {
      MAX_LENGTH -= 1;
      spaceFlag = excerpt[MAX_LENGTH] === ' ';
    }
    excerpt = `${excerpt.slice(0, MAX_LENGTH)} ...`;
  }
  return excerpt;
};
